import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    initialize() {
        super.initialize();
    }

    connect() {
    }

    reset(event) {
        this.element.reset();
        // console.log(this.element.dataset);
        // console.log(e.params);
        // this.dispatch("copy", { target: document, detail: { content: "something" } })
        // document.querySelector('#hello').hello.handle({});
        // Turbo.visit("/messages", { action: action })
    }

}
