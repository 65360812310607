import { Controller } from "@hotwired/stimulus"
import Quagga from 'quagga'; // ES6
import AppQuagga from "../scanner";





export default class extends Controller {

    static values = {
        itemid: String
    }


    connect() {

        if (navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function') {
            // safely access `navigator.mediaDevices.getUserMedia`

            AppQuagga.init(this.itemidValue);



        }


    }


    capture(e) {
        console.log(e)
        alert(e)
    }

    controllerChange(event) {

    }

    stateChange() {
        // perform any visual manipulations here
    }
}



