import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    initialize() {
        super.initialize();
    }

    connect() {
    }

    submit(event) {
        // document.itemsForm.submit();
        console.log(document.itemsForm);
        let confirmed = confirm("Are you sure ?");
        if(!confirmed) {
            event.preventDefault();
        } else {
            document.itemsForm.submit();
        }
    }


}
