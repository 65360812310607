import { Controller } from "@hotwired/stimulus"



export default class extends Controller {
  connect() {

    // var widgetID = hcaptcha.render('captcha-1', { sitekey: 'your_site_key' });
  }

  handle(e) {
  }



}
